import { useState } from "react";
import axios from "axios";
import Tr from "../../../../components/body/table/tr";
import Td from "../../../../components/body/table/tbody/td";
import Icon from "../../../../components/body/icon";
import Regional from "../regional";
import toast from "react-hot-toast";
import "../style.css";

export default function Rede({id}) {

    const [selected, setSelected] = useState(null);
    const [rede, setRede] = useState([
        {
            id: 1, nome: "DML",
            var_c1: 38, var_c2: 27, var_c3: 15, var_c4: 45, var_c5: 27, total_1: 4560,
            var_i1: 15.5, var_i2: 12.5, var_i3: 15.5, var_i4: 14.5, var_i5: 12.5, var_i6: 12.5, var_i7: 15.5, var_i8: 12.5, var_i9: 13.5, var_i10: 13.5, total_2: 6900,
            var_p1: 12.5, var_p2: 17.5, var_p3: 13.5, var_p4: 12.5, var_p5: 15.5, var_p6: 13.5, var_p7: 13.5, var_p8: 14.5, var_p9: 13.5, var_p10: 13.5, var_p11: 2800, total_3: 12161.25
        },
        {
            id: 2, nome: "FML",
            var_c1: 21.5, var_c2: 15, var_c3: 8, var_c4: 25, var_c5: 15, total_1: 2535,
            var_i1: 8.5, var_i2: 6.5, var_i3: 8.5, var_i4: 7.5, var_i5: 7, var_i6: 6.5, var_i7: 8.5, var_i8: 6.5, var_i9: 7.5, var_i10: 7, total_2: 3700,
            var_p1: 12.5, var_p2: 17.5, var_p3: 13.5, var_p4: 12.5, var_p5: 15.5, var_p6: 13.5, var_p7: 13.5, var_p8: 14.5, var_p9: 13.5, var_p10: 13.5, var_p11: 2800, total_3: 12161.25
        },
        {
            id: 3, nome: "TMO",
            var_c1: 38, var_c2: 26, var_c3: 16, var_c4: 45, var_c5: 28, total_1: 4590,
            var_i1: 8.5, var_i2: 6.5, var_i3: 8.5, var_i4: 7.5, var_i5: 7, var_i6: 6.5, var_i7: 8.5, var_i8: 6.5, var_i9: 7.5, var_i10: 7, total_2: 3700,
            var_p1: 12.5, var_p2: 17.5, var_p3: 13.5, var_p4: 12.5, var_p5: 15.5, var_p6: 13.5, var_p7: 13.5, var_p8: 14.5, var_p9: 13.5, var_p10: 13.5, var_p11: 2800, total_3: 12161.25
        },
        {
            id: 4, nome: "ROS",
            var_c1: 38, var_c2: 27, var_c3: 15, var_c4: 45, var_c5: 27, total_1: 4560,
            var_i1: 8.5, var_i2: 6.5, var_i3: 8.5, var_i4: 7.5, var_i5: 7, var_i6: 6.5, var_i7: 8.5, var_i8: 6.5, var_i9: 7.5, var_i10: 7, total_2: 3700,
            var_p1: 12.5, var_p2: 17.5, var_p3: 13.5, var_p4: 12.5, var_p5: 15.5, var_p6: 13.5, var_p7: 13.5, var_p8: 14.5, var_p9: 13.5, var_p10: 13.5, var_p11: 2800, total_3: 12161.25
        }
    ]);

    function get_regional() {
        axios({
            method: 'get',
            url: `${window.backend}/`,
            params: {
                rede_id: id,
            }
        }).then((response) => {
            // setRede(response.data)
        }).catch((_error) => {
            toast("Ocorreu um erro, tente novamente")
        })
    }


    return (

        <>
            {rede.length > 0 &&
                rede.map((rede) => {
                    return (
                        <>
                            <Tr disabled={ selected === null  || selected === rede.id ? false : true}>
                                <Td className="padding-rede" overflow="visible" disableView={false}>
                                    <div className={'d-flex align-items-center justify-content-between cursor-pointer'} onClick={() => setSelected(rede.id === selected ? null : rede.id)}>
                                        {rede.nome}
                                        <Icon type={selected === rede.id ? "chevron-up" : "chevron-down"} />
                                    </div>
                                </Td>

                                <Td>{rede.var_c1}</Td>
                                <Td>{rede.var_c2}</Td>
                                <Td align="center">{rede.var_c3}</Td>
                                <Td>{rede.var_c4}</Td>
                                <Td>{rede.var_c5}</Td>
                                <Td>{rede.total_1}</Td>

                                <Td>{rede.var_i1}</Td>
                                <Td align="center">{rede.var_i2}</Td>
                                <Td>{rede.var_i3}</Td>
                                <Td>{rede.var_i4}</Td>
                                <Td>{rede.var_i5}</Td>
                                <Td>{rede.var_i6}</Td>
                                <Td>{rede.var_i7}</Td>
                                <Td>{rede.var_i8}</Td>
                                <Td>{rede.var_i9}</Td>
                                <Td align="center">{rede.var_i10}</Td>
                                <Td align="center">{rede.total_2}</Td>

                                <Td align="center">{rede.var_p1}</Td>
                                <Td>{rede.var_p2}</Td>
                                <Td align="center">{rede.var_p3}</Td>
                                <Td>{rede.var_p4}</Td>
                                <Td>{rede.var_p5}</Td>
                                <Td>{rede.var_p6}</Td>
                                <Td>{rede.var_p7}</Td>
                                <Td>{rede.var_p8}</Td>
                                <Td align="center">{rede.var_p9}</Td>
                                <Td>{rede.var_p10}</Td>
                                <Td align="center">{rede.var_p11}</Td>
                                <Td align="center">{rede.total_3}</Td>
                            </Tr>
                            {
                                selected === rede.id &&
                                <Regional rede_idid={rede.id} />
                            }
                        </>
                    )
                })}

        </>
    )
}