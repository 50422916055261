import { useEffect, useState } from "react";
import Tr from "../../../../components/body/table/tr";
import Td from "../../../../components/body/table/tbody/td";
import axios from "axios";
import Icon from "../../../../components/body/icon";
import toast from "react-hot-toast";
import "../style.css";

export default function Lider({ id }) {

    const [lider, setLider] = useState([
        {
            id: 1, nome: "Lider 1",
            var_c1: 5, var_c2: 3, var_c3: 1, var_c4: 5, var_c5: 3, total_1: 510,
            var_i1: 15.5, var_i2: 12.5, var_i3: 15.5, var_i4: 14.5, var_i5: 12.5, var_i6: 12.5, var_i7: 15.5, var_i8: 12.5, var_i9: 13.5, var_i10: 13.5, total_2: 500,
            var_p1: 0.5, var_p2: 1.5, var_p3: 0.5, var_p4: 2.5, var_p5: 1.5, var_p6: 0.5, var_p7: 0.5, var_p8: 2.5, var_p9: 0.5, var_p10: 0.5, var_p11: 220, total_3: 1230
        },
        {
            id: 2, nome: "Lider 2",
            var_c1: 5, var_c2: 3, var_c3: 1, var_c4: 5, var_c5: 3, total_1: 510,
            var_i1: 15.5, var_i2: 12.5, var_i3: 15.5, var_i4: 14.5, var_i5: 12.5, var_i6: 12.5, var_i7: 15.5, var_i8: 12.5, var_i9: 13.5, var_i10: 13.5, total_2: 500,
            var_p1: 0.5, var_p2: 1.5, var_p3: 0.5, var_p4: 2.5, var_p5: 1.5, var_p6: 0.5, var_p7: 0.5, var_p8: 2.5, var_p9: 0.5, var_p10: 0.5, var_p11: 220, total_3: 1230
        },
        {
            id: 3, nome: "Lider 3",
            var_c1: 5, var_c2: 3, var_c3: 1, var_c4: 5, var_c5: 3, total_1: 510,
            var_i1: 15.5, var_i2: 12.5, var_i3: 15.5, var_i4: 14.5, var_i5: 12.5, var_i6: 12.5, var_i7: 15.5, var_i8: 12.5, var_i9: 13.5, var_i10: 13.5, total_2: 500,
            var_p1: 0.5, var_p2: 1.5, var_p3: 0.5, var_p4: 2.5, var_p5: 1.5, var_p6: 0.5, var_p7: 0.5, var_p8: 2.5, var_p9: 0.5, var_p10: 0.5, var_p11: 220, total_3: 1230
        },
        {
            id: 4, nome: "Lider 4",
            var_c1: 5, var_c2: 3, var_c3: 1, var_c4: 5, var_c5: 3, total_1: 510,
            var_i1: 15.5, var_i2: 12.5, var_i3: 15.5, var_i4: 14.5, var_i5: 12.5, var_i6: 12.5, var_i7: 15.5, var_i8: 12.5, var_i9: 13.5, var_i10: 13.5, total_2: 500,
            var_p1: 0.5, var_p2: 1.5, var_p3: 0.5, var_p4: 2.5, var_p5: 1.5, var_p6: 0.5, var_p7: 0.5, var_p8: 2.5, var_p9: 0.5, var_p10: 0.5, var_p11: 220, total_3: 1230
        },
        {
            id: 5, nome: "Lider 5",
            var_c1: 5, var_c2: 3, var_c3: 1, var_c4: 5, var_c5: 3, total_1: 510,
            var_i1: 15.5, var_i2: 12.5, var_i3: 15.5, var_i4: 14.5, var_i5: 12.5, var_i6: 12.5, var_i7: 15.5, var_i8: 12.5, var_i9: 13.5, var_i10: 13.5, total_2: 500,
            var_p1: 0.5, var_p2: 1.5, var_p3: 0.5, var_p4: 2.5, var_p5: 1.5, var_p6: 0.5, var_p7: 0.5, var_p8: 2.5, var_p9: 0.5, var_p10: 0.5, var_p11: 220, total_3: 1230
        },
        {
            id: 6, nome: "Lider 6",
            var_c1: 5, var_c2: 3, var_c3: 1, var_c4: 5, var_c5: 3, total_1: 510,
            var_i1: 15.5, var_i2: 12.5, var_i3: 15.5, var_i4: 14.5, var_i5: 12.5, var_i6: 12.5, var_i7: 15.5, var_i8: 12.5, var_i9: 13.5, var_i10: 13.5, total_2: 500,
            var_p1: 0.5, var_p2: 1.5, var_p3: 0.5, var_p4: 2.5, var_p5: 1.5, var_p6: 0.5, var_p7: 0.5, var_p8: 2.5, var_p9: 0.5, var_p10: 0.5, var_p11: 220, total_3: 1230
        },
        {
            id: 7, nome: "Lider 7",
            var_c1: 5, var_c2: 3, var_c3: 1, var_c4: 5, var_c5: 3, total_1: 510,
            var_i1: 15.5, var_i2: 12.5, var_i3: 15.5, var_i4: 14.5, var_i5: 12.5, var_i6: 12.5, var_i7: 15.5, var_i8: 12.5, var_i9: 13.5, var_i10: 13.5, total_2: 500,
            var_p1: 0.5, var_p2: 1.5, var_p3: 0.5, var_p4: 2.5, var_p5: 1.5, var_p6: 0.5, var_p7: 0.5, var_p8: 2.5, var_p9: 0.5, var_p10: 0.5, var_p11: 220, total_3: 1230
        },
        {
            id: 8, nome: "Lider 8",
            var_c1: 5, var_c2: 3, var_c3: 1, var_c4: 5, var_c5: 3, total_1: 510,
            var_i1: 15.5, var_i2: 12.5, var_i3: 15.5, var_i4: 14.5, var_i5: 12.5, var_i6: 12.5, var_i7: 15.5, var_i8: 12.5, var_i9: 13.5, var_i10: 13.5, total_2: 500,
            var_p1: 0.5, var_p2: 1.5, var_p3: 0.5, var_p4: 2.5, var_p5: 1.5, var_p6: 0.5, var_p7: 0.5, var_p8: 2.5, var_p9: 0.5, var_p10: 0.5, var_p11: 220, total_3: 1230
        },
        {
            id: 9, nome: "Lider 9",
            var_c1: 5, var_c2: 3, var_c3: 1, var_c4: 5, var_c5: 3, total_1: 510,
            var_i1: 15.5, var_i2: 12.5, var_i3: 15.5, var_i4: 14.5, var_i5: 12.5, var_i6: 12.5, var_i7: 15.5, var_i8: 12.5, var_i9: 13.5, var_i10: 13.5, total_2: 500,
            var_p1: 0.5, var_p2: 1.5, var_p3: 0.5, var_p4: 2.5, var_p5: 1.5, var_p6: 0.5, var_p7: 0.5, var_p8: 2.5, var_p9: 0.5, var_p10: 0.5, var_p11: 220, total_3: 1230
        },
    ]);

    const [selected, setSelected] = useState(null);

    function get_lider() {
        axios({
            method: 'get',
            url: `${window.backend}/`,
            params: {
                rede_id: id,
            }
        }).then((response) => {
            setLider(response.data)
        }).catch((_error) => {
            toast("Ocorreu um erro, tente novamente")
        })
    }

    useEffect(() => {
        // if(id){
        //     console.log(id)
        // }
    }, [id]);

    return (
        lider.length > 0 &&
        lider.map((item) => {
            return (
                <Tr key={item.id} className="padding-lider">
                    <Td className="padding-lider">{item.nome} </Td>

                    <Td>{item.var_c1}</Td>
                        <Td>{item.var_c2}</Td>
                        <Td align="center">{item.var_c3}</Td>
                        <Td>{item.var_c4}</Td>
                        <Td>{item.var_c5}</Td>
                        <Td>{item.total_1}</Td>

                        <Td>{item.var_i1}</Td>
                        <Td align="center">{item.var_i2}</Td>
                        <Td>{item.var_i3}</Td>
                        <Td>{item.var_i4}</Td>
                        <Td>{item.var_i5}</Td>
                        <Td>{item.var_i6}</Td>
                        <Td>{item.var_i7}</Td>
                        <Td>{item.var_i8}</Td>
                        <Td >{item.var_i9}</Td>
                        <Td align="center">{item.var_i10}</Td>
                        <Td align="center">{item.total_2}</Td>

                        <Td align="center">{item.var_p1}</Td>
                        <Td>{item.var_p2}</Td>
                        <Td align="center">{item.var_p3}</Td>
                        <Td>{item.var_p4}</Td>
                        <Td>{item.var_p5}</Td>
                        <Td>{item.var_p6}</Td>
                        <Td>{item.var_p7}</Td>
                        <Td>{item.var_p8}</Td>
                        <Td align="center">{item.var_p9}</Td>
                        <Td>{item.var_p10}</Td>
                        <Td align="center">{item.var_p11}</Td>
                        <Td align="center">{item.total_3}</Td>
                </Tr>
            )
        })
    )
}