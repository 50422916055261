import { useEffect, useState } from "react";
import Tr from "../../../../components/body/table/tr";
import Td from "../../../../components/body/table/tbody/td";
import axios from "axios";
import Icon from "../../../../components/body/icon";
import toast from "react-hot-toast";
import Lider from "../lider";
import "../style.css";

export default function Regional({ rede_id }) {


    const [regional, setRegional] = useState([
        {
            id: 1, nome: "Regional 1",
            var_c1: 38, var_c2: 15, var_c3: 8, var_c4: 25, var_c5: 15, total_1: 2535,
            var_i1: 8.5, var_i2: 6.5, var_i3: 8.5, var_i4: 7.5, var_i5: 7, var_i6: 6.5, var_i7: 8.5, var_i8: 6.5, var_i9: 7.5, var_i10: 7, total_2: 3700,
            var_p1: 12.5, var_p2: 17.5, var_p3: 13.5, var_p4: 12.5, var_p5: 15.5, var_p6: 13.5, var_p7: 13.5, var_p8: 14.5, var_p9: 13.5, var_p10: 13.5, var_p11: 2800, total_3: 12161.25
        },
        {
            id: 2, nome: "Regional 2",
            var_c1: 38, var_c2: 15, var_c3: 8, var_c4: 25, var_c5: 15, total_1: 2535,
            var_i1: 8.5, var_i2: 6.5, var_i3: 8.5, var_i4: 7.5, var_i5: 7, var_i6: 6.5, var_i7: 8.5, var_i8: 6.5, var_i9: 7.5, var_i10: 7, total_2: 3700,
            var_p1: 12.5, var_p2: 17.5, var_p3: 13.5, var_p4: 12.5, var_p5: 15.5, var_p6: 13.5, var_p7: 13.5, var_p8: 14.5, var_p9: 13.5, var_p10: 13.5, var_p11: 2800, total_3: 12161.25
        },
    ]);

    const [selected, setSelected] = useState(null);

    function get_regional() {
        axios({
            method: 'get',
            url: `${window.backend}/`,
            params: {
                rede_id: rede_id,
            }
        }).then((response) => {
            setRegional(response.data)
        }).catch((_error) => {
            toast("Ocorreu um erro, tente novamente")
        })
    }

    useEffect(() => {
        // if(id){
        //     console.log(rede_id)
        // }
    }, [rede_id]);

    return (
        regional.length > 0 &&
        regional.map((item) => {
            return (
                <>
                    <Tr key={item.id} className="padding-regional" disabled={ selected === null  || selected === item.id ? false : true}>
                        <Td className="padding-regional" overflow="visible" disableView={false}>
                            <div className={'d-flex align-items-center justify-content-between cursor-pointer'} onClick={() => setSelected(selected === item.id ? null : item.id)}>
                                {item.nome} <Icon type={selected === item.id ? "chevron-up" : "chevron-down"} />
                            </div>
                        </Td>

                        <Td>{item.var_c1}</Td>
                        <Td>{item.var_c2}</Td>
                        <Td align="center">{item.var_c3}</Td>
                        <Td>{item.var_c4}</Td>
                        <Td>{item.var_c5}</Td>
                        <Td>{item.total_1}</Td>

                        <Td>{item.var_i1}</Td>
                        <Td align="center">{item.var_i2}</Td>
                        <Td>{item.var_i3}</Td>
                        <Td>{item.var_i4}</Td>
                        <Td>{item.var_i5}</Td>
                        <Td>{item.var_i6}</Td>
                        <Td>{item.var_i7}</Td>
                        <Td>{item.var_i8}</Td>
                        <Td>{item.var_i9}</Td>
                        <Td align="center">{item.var_i10}</Td>
                        <Td align="center">{item.total_2}</Td>

                        <Td align="center">{item.var_p1}</Td>
                        <Td>{item.var_p2}</Td>
                        <Td align="center">{item.var_p3}</Td>
                        <Td>{item.var_p4}</Td>
                        <Td>{item.var_p5}</Td>
                        <Td>{item.var_p6}</Td>
                        <Td>{item.var_p7}</Td>
                        <Td>{item.var_p8}</Td>
                        <Td align="center">{item.var_p9}</Td>
                        <Td>{item.var_p10}</Td>
                        <Td align="center">{item.var_p11}</Td>
                        <Td align="center">{item.total_3}</Td>
                    </Tr>
                    {selected === item.id && <Lider id={item.id} rede_id={rede_id} />}
                </>
            )
        })
    )
}