import { useEffect, useState } from "react";
import Table from "../../../components/body/table";
import Thead from "../../../components/body/table/thead";
import Tr from "../../../components/body/table/tr";
import Th from "../../../components/body/table/thead/th";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Rede from "./rede";
import Container from "../../../components/body/container";
import Input from "../../../components/body/form/input";
import FilterCheckbox from "../../../components/body/filterCheckbox";
import SelectReact from "../../../components/body/select";
import "./style.css";

export default function ListaRegua({ icons, filters }) {

    //ESTADOS
    const [grupo, setGrupo] = useState([
        {
            id: 1, nome: "Rede d1000",
            var_c1: 33.875, var_c2: 22.75, var_c3: 13.5, var_c4: 40, var_c5: 24.25, total_1: 4067.25,
            var_i1: 12, var_i2: 9.5, var_i3: 12, var_i4: 11, var_i5: 9.75, var_i6: 9.5, var_i7: 12, var_i8: 9.5, var_i9: 10.5, var_i10: 10.25, total_2: 5300,
            var_p1: 12.5, var_p2: 17.5, var_p3: 13.5, var_p4: 12.5, var_p5: 15.5, var_p6: 13.5, var_p7: 13.5, var_p8: 14.5, var_p9: 13.5, var_p10: 13.5, var_p11: 2800, total_3: 12161.25
        }
    ]);
    //FILTROS
    const [filterDateMonth, setFilterDateMonth] = useState(new Date(window.currentDate));
    const [filterStore, setFilterStore] = useState();
    const [filterSupervisor, setFilterSupervisor] = useState();
    const [filterEmpreendimento, setFilterEmpreendimento] = useState();
    const [usuario, setUsuario] = useState();

    //HANDLERS
    const handleSetFilterDateMonth = (e) => {
        setFilterDateMonth(e)
    }
    const handleFilterStore = (e) => {
        setFilterStore(e)
    }
    const handleFilterSupervisor = (e) => {
        setFilterSupervisor(e)
    }
    const handleSetFilterEmpreendimento = (e) => {
        setFilterEmpreendimento(e)
    }
    function resetFilter() {

    }

    useEffect(() => {
        if (icons) {
            icons(<></>)
        }
        if (filters) {
            filters(
                <>               
                    <FilterCheckbox
                        api={{
                            url: window.host + '/api/sql.php?do=select&component=grupo_empreendimento&token=' + window.token
                        }}
                        placeholder="Empreendimento"
                        name="filter_id_emp"
                        value={filterEmpreendimento}
                        onChangeClose={(e) => (
                            handleSetFilterEmpreendimento(e), resetFilter()
                        )}
                    >
                        Empreendimentos
                    </FilterCheckbox>

                    <FilterCheckbox
                        name="filter_store"
                        api={window.host + "/api/sql.php?do=select&component=loja&np=true&filial=true&limit=false"}
                        onChangeClose={handleFilterStore}
                    >
                        Loja
                    </FilterCheckbox>

                    <FilterCheckbox
                        name="filter_supervisor"
                        api={window.host + "/api/sql.php?do=select&component=supervisor_2&grupo_id=true&empreendimento_id=" + window.rs_id_emp}
                        onChangeClose={handleFilterSupervisor}
                        value={filterSupervisor}
                    >
                        Regional
                    </FilterCheckbox>

                    <SelectReact
                        api={{
                            url: window.host + '/api/sql.php?do=select&component=usuario',
                            params: {
                                token: window.token,
                                np: true,
                                limit: false,
                                //grupo_id: (filterEmpreendimento.length > 0 ? false : true),
                                empreendimento_id: window.rs_id_emp
                            },
                        }}
                        placeholder="Usuário"
                        name="filter_id_user"
                        value={usuario}
                        onChange={(e) => (
                            setUsuario(e.value)
                        )}
                        allowEmpty={false}
                    />

                    <Input
                        type="date"
                        format="mm/yyyy"
                        icon={false}
                        required={false}
                        value={filterDateMonth}
                        onChange={(e) => handleSetFilterDateMonth(e)}
                    />
                </>
            )
        }
    }, [filterDateMonth, usuario, filterSupervisor, ])

    return (
        <Container>
            <Table>
                <Thead>
                    <Tr>
                        <Th colspan="7" className="regua-cor1" align="center">
                            Competências
                        </Th>
                        <Th colspan="11" className="regua-cor2" align="center">
                            Indicadores
                        </Th>
                        <Th colspan="12" className="regua-cor3" align="center">
                            Processos
                        </Th>
                    </Tr>

                    <Tr>
                        <Th>Avaliado</Th>
                        <Th>Foco no Cliente</Th>
                        <Th>Empatia</Th>
                        <Th>Compromisso Emocional</Th>
                        <Th>Hands On</Th>
                        <Th>Transparência</Th>
                        <Th>Total - Competências</Th>

                        <Th>Venda</Th>
                        <Th>Margem de contribuição</Th>
                        <Th>Incineração</Th>
                        <Th>Inventário</Th>
                        <Th>CRM</Th>
                        <Th>MEX</Th>
                        <Th>TTS</Th>
                        <Th>OPEX</Th>
                        <Th>NPS</Th>
                        <Th>Transmissão de arquivos para ANVISA</Th>
                        <Th>Total indicadores </Th>

                        <Th>Abertura e fechamento da loja</Th>
                        <Th>Escala MOAVI </Th>
                        <Th>Funcionalidade dos equipamentos</Th>
                        <Th>Planogrma </Th>
                        <Th>Precificação</Th>
                        <Th>Financeiro</Th>
                        <Th>TMS</Th>
                        <Th>SPOT</Th>
                        <Th>Controle de estoque </Th>
                        <Th>Regulatório</Th>
                        <Th>Total Processos</Th>
                        <Th>Total Primeira avaliação</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        grupo.length > 0 && grupo.map((item, i) => {
                            return (
                                <Tr key={item.id}>
                                    <Td>{item.nome}</Td>
                                    <Td>{item.var_c1}</Td>
                                    <Td>{item.var_c2}</Td>
                                    <Td align="center">{item.var_c3}</Td>
                                    <Td>{item.var_c4}</Td>
                                    <Td>{item.var_c5}</Td>
                                    <Td>{item.total_1}</Td>

                                    <Td>{item.var_i1}</Td>
                                    <Td align="center">{item.var_i2}</Td>
                                    <Td>{item.var_i3}</Td>
                                    <Td>{item.var_i4}</Td>
                                    <Td>{item.var_i5}</Td>
                                    <Td>{item.var_i6}</Td>
                                    <Td>{item.var_i7}</Td>
                                    <Td>{item.var_i8}</Td>
                                    <Td>{item.var_i9}</Td>
                                    <Td align="center">{item.var_i10}</Td>
                                    <Td align="center">{item.total_2}</Td>

                                    <Td align="center">{item.var_p1}</Td>
                                    <Td>{item.var_p2}</Td>
                                    <Td align="center">{item.var_p3}</Td>
                                    <Td>{item.var_p4}</Td>
                                    <Td>{item.var_p5}</Td>
                                    <Td>{item.var_p6}</Td>
                                    <Td>{item.var_p7}</Td>
                                    <Td>{item.var_p8}</Td>
                                    <Td align="center">{item.var_p9}</Td>
                                    <Td>{item.var_p10}</Td>
                                    <Td align="center">{item.var_p11}</Td>
                                    <Td align="center">{item.total_3}</Td>

                                </Tr>
                            )
                        })
                    }
                    {/* {
                        rede.length > 0 &&
                        rede.map((rede) => {
                            return ( */}
                    <Rede
                    // key={rede.id}
                    // nome={rede.nome}
                    // rede={rede}
                    />
                    {/* )
                        })
                    } */}
                </Tbody>
            </Table>
        </Container>
    )
}